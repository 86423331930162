import React from "react";
import "./Banner.css";

const Banner = () => {
  return (
    <section className="banner">
   
      <div className="product">
        <img src="/banner.png" style={{"width":"95vw"}} alt="Water Purifier" />
        
      </div>
    </section>
  );
};

export default Banner;
